import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { MonthlyWorkLogDto } from '../../../../../../../../../types/dto/work-logs.dto';

interface Props {
  monthlyWorkLog: MonthlyWorkLogDto;
}

const MonthlyWorkLogPDFRecap = ({ monthlyWorkLog }: Props) => {
  const { t } = useTranslation('work-logs');

  const styles = StyleSheet.create({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '30px',
      width: '300px',
    },
    title: {
      fontFamily: 'Roboto',
      fontSize: 8,
      fontWeight: 600,
      paddingBottom: 8,
    },
    tableRow: {
      fontFamily: 'Roboto',
      fontSize: 7,
      fontWeight: 400,
      padding: 2,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      border: '1px solid black',
    },
    bottomTableRow: {
      backgroundColor: '#e6e6e6',
    },
    textBoldUppercase: {
      fontWeight: 600,
    },
  });

  const totalHours = [
    monthlyWorkLog.totalDailyWorkHours ?? 0,
    monthlyWorkLog.totalNightWorkHours ?? 0,
    monthlyWorkLog.totalAnnualLeaveHours ?? 0,
    monthlyWorkLog.totalMedicalLeaveHours ?? 0,
    monthlyWorkLog.totalHolidayHours ?? 0,
    monthlyWorkLog.totalOvertimeHours ?? 0,
  ].reduce((sum, hours) => sum + hours, 0);

  return (
    <View style={styles.wrapper}>
      <Text style={styles.title}>{t('view.summary.title')}</Text>
      <View style={styles.tableRow}>
        <Text>{t('view.summary.totalDaily')}</Text>
        <Text>{monthlyWorkLog.totalDailyWorkHours}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text>{t('view.summary.totalNight')}</Text>
        <Text>{monthlyWorkLog.totalNightWorkHours}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text>{t('view.summary.totalAnnual')}</Text>
        <Text>{monthlyWorkLog.totalAnnualLeaveHours}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text>{t('view.summary.totalMedical')}</Text>
        <Text>{monthlyWorkLog.totalMedicalLeaveHours}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text>{t('view.summary.totalOvertime')}</Text>
        <Text>{monthlyWorkLog.totalOvertimeHours}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text>{t('view.summary.totalHoliday')}</Text>
        <Text>{monthlyWorkLog.totalHolidayHours}</Text>
      </View>
      <View style={[styles.tableRow, styles.bottomTableRow]}>
        <Text style={styles.textBoldUppercase}>{t('view.summary.total')}</Text>
        <Text style={styles.textBoldUppercase}>{totalHours}</Text>
      </View>
    </View>
  );
};

export default MonthlyWorkLogPDFRecap;
